import { BADGE_LIST } from '@/widgets/layouts/main/constants/badges';

import { Article } from '@/shared/components/article';
import { Marquee } from '@/shared/components/marquee';
import { Badge } from '@/shared/components/ui/badge';

export const ForWhomOurLessons = () => {
  return (
    <Article withContainer={false}>
      <div className="container mb-8">
        <h2 className="header-h2">Для кого подойдут наши занятия</h2>
      </div>

      <Marquee pauseOnHover>
        {BADGE_LIST.map((badgeText, index) => (
          <Badge key={index} size="sm">
            {badgeText}
          </Badge>
        ))}
      </Marquee>
    </Article>
  );
};
