import { Article } from '@/shared/components/article';
import { BentoCard, BentoGrid } from '@/shared/components/bento-grid';

import { STUDENTS_CASES } from './constants/cases-list';

export const StudentsCases = () => {
  const studentCases = STUDENTS_CASES.slice(0, 5);
  return (
    <Article>
      <h2 className="mb-8 header-h2">Кейсы учеников</h2>

      <BentoGrid className="grid-rows-auto grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 lg:grid-rows-3">
        {studentCases.map((studentCase, index) => {
          return <BentoCard key={index} {...studentCase} />;
        })}
      </BentoGrid>
    </Article>
  );
};
