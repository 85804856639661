export const WORK_WITH_US_PLUSES_LIST = [
  {
    header: 'Выучите английский для работы и&nbsp;собеседований.',
    text: 'Больше никакой ненужной лексики и&nbsp;скучных тем! Берем только&nbsp;то, что нужно в&nbsp;вашей профессии и&nbsp;отрабатываем на&nbsp;реальных кейсах',
  },

  {
    header: 'Свободно заговорите, даже если вы&nbsp;&mdash; начинающий.',
    text: '80% наших занятий&nbsp;&mdash; speaking и&nbsp;никакой зубрежки! Нам важно, чтобы вы&nbsp;начали свободно использовать английский в&nbsp;деловом и&nbsp;неформальном общении',
  },

  {
    header: 'Увидите свой прогресс',
    text: 'Для каждого студента мы&nbsp;составляем индивидуальную карту&nbsp;&mdash; roadmap, в&nbsp;котором вы&nbsp;будете всегда видеть изучаемые темы. С&nbsp;ним вы&nbsp;убедитесь, что не&nbsp;теряете время зря и&nbsp;точно движетесь к&nbsp;своей цели',
  },

  {
    header: 'Полюбите английский',
    text: 'Наши занятия проходят на&nbsp;интерактивных платформах, чтобы вы&nbsp;строили новые нейронные связи и&nbsp;быстрее запоминали материал. А&nbsp;благодаря актуальным темам, вы&nbsp;будете посещать каждый урок с&nbsp;интересом!',
  },
];
