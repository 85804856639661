import { WHY_WORK_WITH_US_POINTS } from '@/widgets/layouts/main/constants/why-work-with-us-points';

import { Article } from '@/shared/components/article';
import OZONIcon from '@/shared/components/icons/ozon-icon';
import ThunderstormIcon from '@/shared/components/icons/thunderstorm-icon';
import { NumberTicker } from '@/shared/components/number-ticker';
import { TextPlusesList } from '@/shared/components/text-pluses-list';
import { cn } from '@/shared/lib/cn';

import { WORK_WITH_US_PLUSES_LIST } from '../../constants/pluses-list';

interface CardProps {
  children: React.ReactNode;
}

const Card = (props: CardProps) => {
  const { children } = props;

  return (
    <div
      className={cn(
        'group relative flex justify-between gap-x-2 overflow-hidden rounded-xl px-4 py-6 md:gap-x-4',
        // light styles
        'bg-white [box-shadow:0_0_0_1px_rgba(0,0,0,.03),0_2px_4px_rgba(0,0,0,.05),0_12px_24px_rgba(0,0,0,.05)]',
        // dark styles
        'transform-gpu dark:bg-black dark:[border:1px_solid_rgba(255,255,255,.1)] dark:[box-shadow:0_-20px_80px_-20px_#ffffff1f_inset]',
      )}
    >
      {children}
    </div>
  );
};

export const WhyWorkWithUs = () => {
  return (
    <Article>
      <h2 className="mb-4 header-h2 sm:mb-8">Почему выбирают нас</h2>

      <div className="grid gap-6 sm:gap-10 md:grid-cols-2 md:grid-rows-none lg:gap-20">
        <TextPlusesList items={WORK_WITH_US_PLUSES_LIST} />

        <div className="space-y-4">
          <Card>
            <div className="w-full max-w-[25%] xl:max-w-[20%]">
              <OZONIcon className="mr-auto w-[60px] xs:w-[80px] lg:w-[100px]" />
            </div>

            <div className="flex-1 space-y-1">
              <h3 className="mb-2 header-h3">Официальный партнер</h3>
              <p className="text-sm text-muted-foreground">
                Обучаем английскому сотрудников Ozon на корпоративных занятиях
              </p>
            </div>
          </Card>

          <Card>
            <div className="w-full max-w-[25%] text-base font-semibold sm:text-lg xl:max-w-[20%]">
              &gt;&nbsp;
              <NumberTicker value={300} />
            </div>

            <div className="flex-1">
              <h3 className="mb-2 header-h3">Учеников</h3>
              <p className="text-sm text-muted-foreground">Повысивших свой уровень английского</p>
            </div>
          </Card>

          <Card>
            <div className="w-full max-w-[25%] text-base font-semibold sm:text-lg xl:max-w-[20%]">15</div>

            <div className="flex-1">
              <h3 className="mb-2 header-h3">Преподавателей</h3>
              <p className="text-sm text-muted-foreground">Готовых учить и&nbsp;помогать достигать цели</p>
            </div>
          </Card>
        </div>
      </div>
    </Article>
  );
};
