export const BADGE_LIST = [
  'Рекрутеры',
  'Product менеджеры',
  'SMM',
  'Владельцы бизнеса',
  'HR',
  'Маркетологи',
  'Руководители',
  'PR',
  'IT Специалисты',
  'Аналитики данных',
  'UX/UI дизайнеры',
  'Project менеджеры',
  'Тестировщики ПО',
  'Бизнес-консультанты',
  'Системные администраторы',
  'Владельцы продукта',
];
