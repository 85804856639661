import { GetServerSideProps } from 'next';
import { allSettled, fork, serialize } from 'effector';

import { homePage, HomePage } from '@/page-content/home';

import { EFFECTOR_STATE_KEY } from '@/shared/lib/effector';
import { getPageContext } from '@/shared/lib/effector/page';

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const scope = fork();

  await allSettled(homePage.open, { scope, params: getPageContext(ctx) });

  return {
    props: {
      [EFFECTOR_STATE_KEY]: serialize(scope),
    },
  };
};

export default HomePage;
